export type Maybe<T> = T | null;

export interface MailInput {
  subject: string;

  body: string;
}

export interface CallInput {
  result: CallResultEnum;

  resultExtra?: Maybe<CallResultExtraEnum>;

  meetingDate?: Maybe<DateTime>;

  meetingZip?: Maybe<ShortStringScalar>;

  meetingAddress?: Maybe<MediumStringScalar>;

  meetingCity?: Maybe<MediumStringScalar>;

  callBackDate?: Maybe<DateTime>;

  comment?: Maybe<LongStringScalar>;

  commercial?: Maybe<MediumStringScalar>;
}

export interface ContactInput {
  civility?: Maybe<CivilityEnum>;

  firstName?: Maybe<MediumStringScalar>;

  lastName?: Maybe<MediumStringScalar>;

  email?: Maybe<EmailScalar>;

  phoneNumber?: Maybe<PhoneNumberScalar>;

  personalPhoneNumber?: Maybe<PhoneNumberScalar>;

  professionalPhoneNumber?: Maybe<PhoneNumberScalar>;

  birthDate?: Maybe<DateTime>;

  familySituation?: Maybe<FamilySituationEnum>;

  kids?: Maybe<number>;

  revenu?: Maybe<RevenuEnum>;

  tax?: Maybe<TaxEnum>;

  residence?: Maybe<ResidenceEnum>;

  zip?: Maybe<ShortStringScalar>;

  city?: Maybe<MediumStringScalar>;

  address?: Maybe<MediumStringScalar>;

  netMensuelRevenu?: Maybe<number>;

  mensuelCredit?: Maybe<number>;

  mensuelConsommationCredit?: Maybe<number>;

  mensuelPension?: Maybe<number>;
}

export interface UserInput {
  username: MediumStringScalar;

  firstName: MediumStringScalar;

  lastName: MediumStringScalar;

  role: UserRoleEnum;

  isActive: boolean;

  externalId?: Maybe<number>;

  isTest: boolean;

  password?: Maybe<PasswordScalar>;
}

export enum CivilityEnum {
  Mr = "MR",
  Mrs = "MRS",
  Miss = "MISS"
}

export enum CallResultEnum {
  Unknown = "UNKNOWN",
  Wrong = "WRONG",
  NoAnswer = "NO_ANSWER",
  OutOfTarget = "OUT_OF_TARGET",
  NotInterested = "NOT_INTERESTED",
  CallBack = "CALL_BACK",
  Meeting = "MEETING"
}

export enum FamilySituationEnum {
  Single = "SINGLE",
  Maried = "MARIED",
  Partnership = "PARTNERSHIP",
  Divorced = "DIVORCED",
  Widower = "WIDOWER"
}

export enum RevenuEnum {
  RevenuLess_25000 = "REVENU_LESS_25000",
  Revenu_25000_30000 = "REVENU_25000_30000",
  Revenu_30000_35000 = "REVENU_30000_35000",
  Revenu_35000_40000 = "REVENU_35000_40000",
  Revenu_40000_50000 = "REVENU_40000_50000",
  Revenu_50000_60000 = "REVENU_50000_60000",
  Revenu_60000_80000 = "REVENU_60000_80000",
  Revenu_80000_100000 = "REVENU_80000_100000",
  RevenuMore_100000 = "REVENU_MORE_100000"
}

export enum TaxEnum {
  TaxLess_1500 = "TAX_LESS_1500",
  Tax_1500_2500 = "TAX_1500_2500",
  Tax_2500_5000 = "TAX_2500_5000",
  Tax_5000_10000 = "TAX_5000_10000",
  TaxMore_10000 = "TAX_MORE_10000"
}

export enum ResidenceEnum {
  Owner = "OWNER",
  Lodger = "LODGER",
  Free = "FREE"
}

export enum UserRoleEnum {
  Admin = "ADMIN",
  Operator = "OPERATOR"
}

export enum CallResultExtraEnum {
  AcquirePrincipalResidence = "ACQUIRE_PRINCIPAL_RESIDENCE",
  Concurrent = "CONCURRENT",
  NotConveniant = "NOT_CONVENIANT",
  MoreThan_65 = "MORE_THAN_65",
  NotFinancable = "NOT_FINANCABLE",
  OutTax = "OUT_TAX",
  WrongNumber = "WRONG_NUMBER",
  WrongDetails = "WRONG_DETAILS"
}

/** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
export type DateTime = any;

/** The MediumStringScalar represents a custom String, trimed, minimum length:1, maximum length:100. */
export type MediumStringScalar = any;

/** The ShortStringScalar represents a custom String, trimed, minimum length:1, maximum length:10. */
export type ShortStringScalar = any;

/** The EmailScalar represents a valid lowercase email. */
export type EmailScalar = any;

/** The PhoneNumberScalar represents a valid phone number. */
export type PhoneNumberScalar = any;

/** The LongStringScalar represents a custom String, trimed, minimum length:1, maximum length:1000. */
export type LongStringScalar = any;

/** The PasswordScalar represents a custom String, minimum length:8, maximum length:20. */
export type PasswordScalar = any;

// ====================================================
// Documents
// ====================================================

export namespace CallAdd {
  export type Variables = {
    callInput: CallInput;
    sessionId: string;
    contactId: string;
  };

  export type Mutation = {
    __typename?: "Mutation";

    callAdd: CallAdd;
  };

  export type CallAdd = {
    __typename?: "CallType";

    id: string;
  };
}

export namespace CallAddressCorrect {
  export type Variables = {
    address: MediumStringScalar;
    zip: ShortStringScalar;
  };

  export type Query = {
    __typename?: "Query";

    callAddressCorrect: string[];
  };
}

export namespace CallSendMail {
  export type Variables = {
    contactId: string;
    mailInput: MailInput;
  };

  export type Mutation = {
    __typename?: "Mutation";

    callSendMail: boolean;
  };
}

export namespace CallMail {
  export type Variables = {
    contactId: string;
  };

  export type Query = {
    __typename?: "Query";

    callMail: CallMail;
  };

  export type CallMail = {
    __typename?: "MailType";

    body: string;

    subject: string;
  };
}

export namespace CallProgram {
  export type Variables = {
    contactId: string;
  };

  export type Mutation = {
    __typename?: "Mutation";

    callProgram: boolean;
  };
}

export namespace Calls {
  export type Variables = {
    page: number;
  };

  export type Query = {
    __typename?: "Query";

    calls: Maybe<Calls[]>;
  };

  export type Calls = {
    __typename?: "CallType";

    id: string;

    firstName: Maybe<MediumStringScalar>;

    lastName: Maybe<MediumStringScalar>;

    civility: Maybe<CivilityEnum>;

    result: CallResultEnum;

    createdAt: DateTime;

    updatedAt: DateTime;
  };
}

export namespace CallCommercials {
  export type Variables = {
    zip: ShortStringScalar;
  };

  export type Query = {
    __typename?: "Query";

    callCommercials: CallCommercials[];
  };

  export type CallCommercials = {
    __typename?: "CommercialType";

    identifier: MediumStringScalar;

    fullName: MediumStringScalar;

    meetingDays: MeetingDays[];
  };

  export type MeetingDays = {
    __typename?: "MeetingDayType";

    date: DateTime;

    availabilities: Availabilities[];
  };

  export type Availabilities = {
    __typename?: "AvailabilityType";

    isAvailable: boolean;

    date: DateTime;

    reason: Maybe<MediumStringScalar>;
  };
}

export namespace ContactEdit {
  export type Variables = {
    contactId: string;
    contactInput: ContactInput;
  };

  export type Mutation = {
    __typename?: "Mutation";

    contactEdit: ContactEdit;
  };

  export type ContactEdit = {
    __typename?: "ContactType";

    id: string;
  };
}

export namespace ContactRequest {
  export type Variables = {
    sessionId: string;
    instanceId?: Maybe<number>;
  };

  export type Query = {
    __typename?: "Query";

    contactRequest: Maybe<ContactRequest>;
  };

  export type ContactRequest = {
    __typename?: "ContactType";

    id: string;

    civility: Maybe<CivilityEnum>;

    firstName: Maybe<MediumStringScalar>;

    lastName: Maybe<MediumStringScalar>;

    email: Maybe<EmailScalar>;

    phoneNumber: Maybe<PhoneNumberScalar>;

    personalPhoneNumber: Maybe<PhoneNumberScalar>;

    professionalPhoneNumber: Maybe<PhoneNumberScalar>;

    birthDate: Maybe<DateTime>;

    familySituation: Maybe<FamilySituationEnum>;

    kids: Maybe<number>;

    revenu: Maybe<RevenuEnum>;

    tax: Maybe<TaxEnum>;

    residence: Maybe<ResidenceEnum>;

    zip: Maybe<ShortStringScalar>;

    city: Maybe<MediumStringScalar>;

    address: Maybe<MediumStringScalar>;

    isNew: Maybe<boolean>;

    isRecall: Maybe<boolean>;

    totalMeetings: Maybe<number>;

    type: Maybe<MediumStringScalar>;

    comment: Maybe<LongStringScalar>;

    message: Maybe<LongStringScalar>;
  };
}

export namespace SessionRequest {
  export type Variables = {};

  export type Query = {
    __typename?: "Query";

    sessionRequest: Maybe<SessionRequest>;
  };

  export type SessionRequest = {
    __typename?: "SessionType";

    id: string;

    token: string;
  };
}

export namespace TokenRefresh {
  export type Variables = {
    refreshToken: string;
  };

  export type Mutation = {
    __typename?: "Mutation";

    tokenRefresh: TokenRefresh;
  };

  export type TokenRefresh = {
    __typename?: "TokenType";

    accessToken: string;

    refreshToken: string;
  };
}

export namespace TokenSignOutAll {
  export type Variables = {};

  export type Mutation = {
    __typename?: "Mutation";

    tokenSignOutAll: boolean;
  };
}

export namespace TokenSignOut {
  export type Variables = {};

  export type Mutation = {
    __typename?: "Mutation";

    tokenSignOut: boolean;
  };
}

export namespace UserMe {
  export type Variables = {};

  export type Query = {
    __typename?: "Query";

    userMe: UserMe;
  };

  export type UserMe = {
    __typename?: "UserType";

    firstName: MediumStringScalar;

    lastName: MediumStringScalar;

    role: UserRoleEnum;
  };
}

export namespace UserSignIn {
  export type Variables = {
    username: MediumStringScalar;
    password: PasswordScalar;
  };

  export type Mutation = {
    __typename?: "Mutation";

    userSignIn: UserSignIn;
  };

  export type UserSignIn = {
    __typename?: "TokenType";

    accessToken: string;

    refreshToken: string;
  };
}

// ====================================================
// START: Apollo Angular template
// ====================================================

import { Injectable } from "@angular/core";
import * as Apollo from "apollo-angular";

import gql from "graphql-tag";

// ====================================================
// Apollo Services
// ====================================================

@Injectable({
  providedIn: "root"
})
export class CallAddGQL extends Apollo.Mutation<
  CallAdd.Mutation,
  CallAdd.Variables
> {
  document: any = gql`
    mutation callAdd($callInput: CallInput!, $sessionId: ID!, $contactId: ID!) {
      callAdd(
        sessionId: $sessionId
        contactId: $contactId
        callInput: $callInput
      ) {
        id
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class CallAddressCorrectGQL extends Apollo.Query<
  CallAddressCorrect.Query,
  CallAddressCorrect.Variables
> {
  document: any = gql`
    query callAddressCorrect(
      $address: MediumStringScalar!
      $zip: ShortStringScalar!
    ) {
      callAddressCorrect(address: $address, zip: $zip)
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class CallSendMailGQL extends Apollo.Mutation<
  CallSendMail.Mutation,
  CallSendMail.Variables
> {
  document: any = gql`
    mutation callSendMail($contactId: ID!, $mailInput: MailInput!) {
      callSendMail(contactId: $contactId, mailInput: $mailInput)
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class CallMailGQL extends Apollo.Query<
  CallMail.Query,
  CallMail.Variables
> {
  document: any = gql`
    query callMail($contactId: ID!) {
      callMail(contactId: $contactId) {
        body
        subject
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class CallProgramGQL extends Apollo.Mutation<
  CallProgram.Mutation,
  CallProgram.Variables
> {
  document: any = gql`
    mutation callProgram($contactId: ID!) {
      callProgram(contactId: $contactId)
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class CallsGQL extends Apollo.Query<Calls.Query, Calls.Variables> {
  document: any = gql`
    query calls($page: Float!) {
      calls(page: $page) {
        id
        firstName
        lastName
        civility
        result
        createdAt
        updatedAt
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class CallCommercialsGQL extends Apollo.Query<
  CallCommercials.Query,
  CallCommercials.Variables
> {
  document: any = gql`
    query callCommercials($zip: ShortStringScalar!) {
      callCommercials(zip: $zip) {
        identifier
        fullName
        meetingDays {
          date
          availabilities {
            isAvailable
            date
            reason
          }
        }
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class ContactEditGQL extends Apollo.Mutation<
  ContactEdit.Mutation,
  ContactEdit.Variables
> {
  document: any = gql`
    mutation contactEdit($contactId: ID!, $contactInput: ContactInput!) {
      contactEdit(contactId: $contactId, contactInput: $contactInput) {
        id
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class ContactRequestGQL extends Apollo.Query<
  ContactRequest.Query,
  ContactRequest.Variables
> {
  document: any = gql`
    query contactRequest($sessionId: ID!, $instanceId: Float) {
      contactRequest(sessionId: $sessionId, instanceId: $instanceId) {
        id
        civility
        firstName
        lastName
        email
        phoneNumber
        personalPhoneNumber
        professionalPhoneNumber
        birthDate
        familySituation
        kids
        revenu
        tax
        residence
        zip
        city
        address
        isNew
        isRecall
        totalMeetings
        type
        comment
        message
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class SessionRequestGQL extends Apollo.Query<
  SessionRequest.Query,
  SessionRequest.Variables
> {
  document: any = gql`
    query sessionRequest {
      sessionRequest {
        id
        token
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class TokenRefreshGQL extends Apollo.Mutation<
  TokenRefresh.Mutation,
  TokenRefresh.Variables
> {
  document: any = gql`
    mutation tokenRefresh($refreshToken: String!) {
      tokenRefresh(refreshToken: $refreshToken) {
        accessToken
        refreshToken
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class TokenSignOutAllGQL extends Apollo.Mutation<
  TokenSignOutAll.Mutation,
  TokenSignOutAll.Variables
> {
  document: any = gql`
    mutation tokenSignOutAll {
      tokenSignOutAll
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class TokenSignOutGQL extends Apollo.Mutation<
  TokenSignOut.Mutation,
  TokenSignOut.Variables
> {
  document: any = gql`
    mutation tokenSignOut {
      tokenSignOut
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class UserMeGQL extends Apollo.Query<UserMe.Query, UserMe.Variables> {
  document: any = gql`
    query userMe {
      userMe {
        firstName
        lastName
        role
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class UserSignInGQL extends Apollo.Mutation<
  UserSignIn.Mutation,
  UserSignIn.Variables
> {
  document: any = gql`
    mutation userSignIn(
      $username: MediumStringScalar!
      $password: PasswordScalar!
    ) {
      userSignIn(username: $username, password: $password) {
        accessToken
        refreshToken
      }
    }
  `;
}

// ====================================================
// END: Apollo Angular template
// ====================================================
