import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CallProgramGQL, CallResultEnum, Calls, CallsGQL, CivilityEnum } from 'src/app/graphql/generated/graphql';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss']
})
export class AgendaComponent implements OnInit {

  error = null;
  isLoading = false;

  hamadi = 'hello';
  pageNumber = 0;

  calls: Calls.Calls[] = [];

  constructor(private callsGQL: CallsGQL, private callProgramGQL: CallProgramGQL, private router: Router) { }

  ngOnInit() {
    this.loadPage();
  }

  loadPage() {

    this.isLoading = true;
    this.callsGQL.fetch({
      page: this.pageNumber
    }).subscribe(result => {

      if (result.data != null) {

        this.isLoading = false;

        this.calls = result.data.calls;
      } else {

        this.error = 'Erreur de chargement de l\'agenda';
      }

    }, _ => {
      this.error = 'Erreur de chargement de l\'agenda';
    });
  }

  program(call: Calls.Calls) {

    if (call.result !== CallResultEnum.NoAnswer && call.result !== CallResultEnum.CallBack) {

      this.error = 'Cette fiche a été cloturée.';

      return;
    }

    this.isLoading = true;
    this.callProgramGQL.mutate({
      contactId: call.id,
    }).subscribe(result => {

      if (result.data != null) {

        this.router.navigate(['call'], { queryParams: { 'is-one-call': 'true' } });
      } else {

        this.error = result.errors[0].message;
        this.isLoading = false;
      }

    }, _ => {
      this.error = 'Erreur de programmation de la fiche.';
    });

  }

  translateCivility(civility: CivilityEnum) {

    switch (civility) {

      case CivilityEnum.Mr:
        return 'Mr';
      case CivilityEnum.Mrs:
        return 'Madame';
      case CivilityEnum.Mr:
        return 'Mademoiselle';
    }
  }

  translateResult(result: CallResultEnum): string {

    switch (result) {

      case CallResultEnum.CallBack:
        return 'Relance tel';

      case CallResultEnum.Meeting:
        return 'Rendez-vous';

      case CallResultEnum.NoAnswer:
        return 'NRP';

      case CallResultEnum.NotInterested:
        return 'Non intéressé';

      case CallResultEnum.OutOfTarget:
        return 'Hors cible';

      case CallResultEnum.Wrong:
        return 'Faux';
    }
  }

}
