import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserSignInGQL } from '../../graphql/generated/graphql';
import { AuthService } from '../../services/auth.service';
import { ErrorService } from '../../services/error.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  isLoading = false;
  errors: any = {};

  username: string;
  password: string;

  constructor(private userSignInGQL: UserSignInGQL,
              private router: Router,
              private authService: AuthService,
              private errorService: ErrorService) { }

  ngOnInit() {
    this.errorService.removeAll();
  }

  onSubmit() {

    this.isLoading = true;
    this.userSignInGQL.mutate({ username: this.username, password: this.password }).subscribe(result => {

    if (result.data != null) {

      this.errors = {};

      const accessToken = result.data.userSignIn.accessToken;
      const refreshToken = result.data.userSignIn.refreshToken;

      this.authService.signIn(accessToken, refreshToken).then(_ => {
        this.authService.navigateToHomePage();
        this.isLoading = false;
      }, _ => {});
    } else {
      this.errors = {_: result.errors[0].message };
      this.isLoading = false;
    }

    }, _ => {});

  }

}
