import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  static URL: 'https://app.scalyr.com/api/uploadLogs?token=0Q9ZuvymPlsiu_t8gWXTtR0P3fpd5nQ9p0fQietWlxNc-';

  static sharedInstance: LoggerService;

  constructor(private http: HttpClient) {

    LoggerService.sharedInstance = this;
  }

  submitLog(log: string, request: string) {

    this.http.post(LoggerService.URL, {
      log,
      request
    }).subscribe((response) => {
    },
      (httpError: HttpErrorResponse) => {
      });
  }
}
