import { Component, OnInit } from '@angular/core';
import { TokenSignOutGQL } from '../../graphql/generated/graphql';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  isLoading = false;
  errors: any = {};

  constructor(public authService: AuthService,
              private tokenSignOutGQL: TokenSignOutGQL) { }

  ngOnInit() {
  }

  signOut() {

    this.isLoading = true;
    this.tokenSignOutGQL.mutate().subscribe(result => {

      if (result.data != null) {
        this.errors = {};
        this.authService.signOut();
        this.isLoading = false;
      } else {
        this.errors._ = result.errors[0].message;
        this.isLoading = false;
      }
    }, _ => {});
  }
}
