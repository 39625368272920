import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { UserMe, UserMeGQL,
         UserRoleEnum, TokenRefreshGQL } from '../graphql/generated/graphql';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  static sharedInstance: AuthService;

  static readonly authRefreshErrorKey = 'AUTH_REFRESH_ERROR';

  userMe?: UserMe.UserMe;

  constructor(private userMeGQL: UserMeGQL,
              private errorService: ErrorService,
              private tokenRefreshGQL: TokenRefreshGQL,
              private router: Router) {

      AuthService.sharedInstance = this;

      if (this.isConnected()) {
        this.loadUserMe();
      }
  }

  get role(): UserRoleEnum {
      return localStorage.getItem('role') as UserRoleEnum;
  }

  set role(role: UserRoleEnum) {
      localStorage.setItem('role', role);
  }

  get accessToken(): string {
      return localStorage.getItem('accessToken');
  }

  set accessToken(accessToken: string) {
      localStorage.setItem('accessToken', accessToken);
  }

  get refreshToken(): string {
      return localStorage.getItem('refreshToken');
  }

  set refreshToken(refreshToken: string) {
      localStorage.setItem('refreshToken', refreshToken);
  }

  signIn(accessToken: string, refreshToken: string): Promise<void> {

    this.accessToken = accessToken;
    this.refreshToken = refreshToken;

    return this.loadUserMe();
  }

  signOut() {

      const currentRole = this.role;

      this.userMe = null;
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('role');

      this.router.navigateByUrl('/sign-in');
  }

  isConnected(): boolean {

    if (this.accessToken == null || this.refreshToken == null || this.role == null) {
        return false;
    }

    return true;
  }

  navigateToHomePage() {
    this.router.navigateByUrl('/');
  }

  navigateToDefaultSignInPage() {
    this.router.navigateByUrl('/sign-in');
  }

  refreshAccessToken(): Promise<void> {

    return new Promise<void>((resolve, reject) => {

      if (this.refreshToken == null) {
        reject();
        return;
      }

      this.tokenRefreshGQL.mutate({ refreshToken: this.refreshToken }).subscribe(result => {

        if (result.data != null) {

          this.accessToken = result.data.tokenRefresh.accessToken;
          this.refreshToken = result.data.tokenRefresh.refreshToken;
          this.loadUserMe();

          resolve();
        } else {
          reject();
        }

      }, _ => { reject(); });

    });
  }

  private loadUserMe(): Promise<void> {

    return new Promise<void>((resolve, reject) => {

      this.userMeGQL.fetch({}).subscribe(result => {

        if (result.data != null) {
          this.userMe = result.data.userMe;
          this.role = this.userMe.role;

          this.errorService.removeError(AuthService.authRefreshErrorKey);

          resolve();

        } else {
          this.errorService.addError(AuthService.authRefreshErrorKey, result.errors[0].message);
          reject();
        }

      }, _ => { reject(); });
    });
  }
}
