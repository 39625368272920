import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Device, Connection } from 'twilio-client';
import { CallStatusEnum } from '../enum/call-status.enum';
import {
  CallAddGQL, CallInput, CallResultEnum, ContactRequest, ContactRequestGQL,
  SessionRequest, SessionRequestGQL
} from '../graphql/generated/graphql';
import { CallHandlerInterface } from '../interfaces/call-handler.interface';

@Injectable({
  providedIn: 'root'
})
export class CallService {

  callStatus: CallStatusEnum;
  contact: ContactRequest.ContactRequest;
  session: SessionRequest.SessionRequest;

  private twilioDevice: Device;

  private deviceCounter = 0;
  private isRecall = false;

  private connection: Connection;

  private isOneCall = false;

  constructor(private sessionRequestGQL: SessionRequestGQL,
              private contactRequestGQL: ContactRequestGQL,
              private callAddGQL: CallAddGQL,
              private instanceId: number,
              private callHandler: CallHandlerInterface,
              private router: Router) { }

  resume(isOneCall = false) {

    this.isOneCall = isOneCall;

    this.twilioDevice = null;
    this.session = null;
    this.contact = null;
    this.deviceCounter++;
    this.isRecall = false;

    this.callStatus = CallStatusEnum.PREPARING;
    this.requestSession();
  }

  pause() {

    this.deviceCounter++;

    if (this.twilioDevice != null) {
      this.twilioDevice.disconnectAll();
    }
  }

  recall() {

    this.callStatus = CallStatusEnum.PREPARING;
    this.isRecall = true;
    this.twilioCallContact();
  }

  disconnect() {

    this.twilioDevice.disconnectAll();
  }

  private requestSession() {

    const deviceCounter = this.deviceCounter;
    this.sessionRequestGQL.fetch().subscribe(result => {

      if (deviceCounter !== this.deviceCounter) {
        return;
      }

      if (result.data != null) {

        this.session = result.data.sessionRequest;

        this.twilioSetupDevice();
      } else {

        if (this.callHandler) {
          this.callHandler.onError(result.errors[0].message, this);
        }
      }
    }, _ => { });
  }

  private twilioSetupDevice() {

    this.twilioDevice = new Device(this.session.token, {
      codecPreferences: [Connection.Codec.Opus],
      fakeLocalDTMF: true,
      enableRingingState: true
    });

    const deviceCounter = this.deviceCounter;

    const twilioDevice = this.twilioDevice;

    this.twilioDevice.on('ready', () => {

      if (deviceCounter !== this.deviceCounter) {
        return;
      }

      this.requestContact();
    });

    this.twilioDevice.on('connect', () => {

      if (deviceCounter !== this.deviceCounter) {
        twilioDevice.disconnectAll();
        return;
      }

      this.connection.mute(false);

      this.callStatus = CallStatusEnum.IN_PROGRESS;

      if (this.callHandler) {
        this.callHandler.onAnswered(this);
      }
    });

    this.twilioDevice.on('disconnect', (param) => {

      if (deviceCounter !== this.deviceCounter) {
        return;
      }

      if (this.callStatus === CallStatusEnum.DONE) {
        return;
      }

      if (this.callStatus === CallStatusEnum.PREPARING && this.isRecall === false) {

        this.submitNRP();
      } else if (this.callHandler) {
        this.callHandler.onDone(this);
      }

      this.callStatus = CallStatusEnum.DONE;
    });
  }

  private requestContact() {

    const deviceCounter = this.deviceCounter;
    this.contactRequestGQL.fetch({ sessionId: this.session.id, instanceId: this.instanceId }).subscribe(result => {

      if (deviceCounter !== this.deviceCounter) {
        return;
      }

      if (result.data != null) {

        this.contact = result.data.contactRequest;

        this.twilioCallContact();
      } else {

        this.resume();
      }

    }, _ => {
      this.resume();
    });
  }

  private twilioCallContact() {

    if (this.contact == null) {
      this.callHandler.onError('Numéro non rensigné.', this);
      return;
    }

    if (this.contact.phoneNumber == null) {

      if (this.contact.personalPhoneNumber != null) {
        this.contact.phoneNumber = this.contact.personalPhoneNumber;
      } else {
        if (this.contact.professionalPhoneNumber != null) {
          this.contact.phoneNumber = this.contact.professionalPhoneNumber;
        }
      }
    }

    if (this.contact.phoneNumber == null) {
      this.callHandler.onError('Numéro non rensigné.', this);
      return;
    }

    this.callStatus = CallStatusEnum.PREPARING;

    const params = { phoneNumber: this.contact.phoneNumber };
    this.connection = this.twilioDevice.connect(params);
    this.connection.mute(true);
  }

  private submitNRP() {

    const callInput: CallInput = {
      result: CallResultEnum.NoAnswer
    };

    const deviceCounter = this.deviceCounter;

    this.callAddGQL.mutate({
      contactId: this.contact.id,
      sessionId: this.session.id,
      callInput
    }).subscribe(result => {

      if (this.deviceCounter !== deviceCounter) {
        return;
      }

      if (result.data != null) {

        if (this.isOneCall) {
          this.router.navigate(['agenda']);
        } else {
          this.resume();
        }
      } else {

        if (this.callHandler) {
          this.callHandler.onError(result.errors[0].message, this);
        }
      }

    }, _ => { });
  }
}
