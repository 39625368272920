import { Injectable } from '@angular/core';
import { ErrorInterface } from '../interfaces/error.interface';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  static sharedInstance: ErrorService;

  errors: ErrorInterface[] = [];

  constructor(private router: Router) {
    ErrorService.sharedInstance = this;
  }

  addError(key, errorMessage) {
    this.removeError(key);
    this.errors.push({ key, errorMessage });
  }

  removeError(key) {

    const newErrors: ErrorInterface[] = [];

    for (const error of this.errors) {

      if (error.key !== key) {
        newErrors.push(error);
      }
    }

    this.errors = newErrors;
  }

  removeAll() {

    this.errors = [];
  }

  navigateToErrorPage() {
    this.router.navigateByUrl('/sign-in');
  }

}
