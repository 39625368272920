import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { ApolloLink } from 'apollo-link';
import { HttpLink, HttpLinkModule } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { ErrorService } from './services/error.service';
import { setContext } from 'apollo-link-context';
import { fromPromise } from 'apollo-link';

import { AuthService } from './services/auth.service';
import { LoggerService } from './services/logger.service';

const networkErrorKey = 'NETWORK_ERROR';

export function createApollo(httpLink: HttpLink) {

  const setAuthorizationLink = setContext((request, previousContext) => {

    let context = {};

    const accessToken = AuthService.sharedInstance.accessToken;

    if (accessToken != null) {
      context = {
        headers: { authorization: accessToken }
      };
    }

    return context;
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {

    if (graphQLErrors) {
      LoggerService.sharedInstance.submitLog(
        JSON.stringify(graphQLErrors), JSON.stringify(operation)
      );
    } else if (networkError) {
      LoggerService.sharedInstance.submitLog(
        JSON.stringify(networkError), JSON.stringify(operation)
      );
    }

    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        switch (err.extensions.code) {
          case 'UNAUTHENTICATED':

            return fromPromise(
              AuthService.sharedInstance.refreshAccessToken().catch(error => {
                AuthService.sharedInstance.signOut();
                return;
              })
            ).flatMap(() => {
              return forward(operation);
            });
        }
      }

    }

    if (networkError) {
      ErrorService.sharedInstance.addError(networkErrorKey, 'Problème de connexion Internet.');
    }
  });

  const errorRemoverLink = new ApolloLink((operation, forward) => {

    return forward(operation).map((data) => {
      ErrorService.sharedInstance.removeError(networkErrorKey);
      return data;
    });
  });

  const link = ApolloLink.from([
    errorRemoverLink,
    errorLink,
    setAuthorizationLink,
    httpLink.create({ uri: 'https://api.callcenter.adomos.com/graphql' }),
    // httpLink.create({ uri: 'http://localhost:8050/graphql' }),
  ]);

  return {
    link,
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      },
      query: {
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      },
      mutate: {
        errorPolicy: 'all'
      }
    }
  };
}

@NgModule({
  exports: [ApolloModule, HttpLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule { }
