import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CallAddGQL, ContactRequestGQL, SessionRequestGQL } from '../graphql/generated/graphql';
import { CallHandlerInterface } from '../interfaces/call-handler.interface';
import { CallService } from './call.service';

@Injectable({
  providedIn: 'root'
})
export class MultiCallService implements CallHandlerInterface {

  currentCallService?: CallService;
  callHandler?: CallHandlerInterface;

  private instancesNumber = 2;
  private callServices: CallService[] = [];

  constructor(private sessionRequestGQL: SessionRequestGQL,
              private contactRequestGQL: ContactRequestGQL,
              private callAddGQL: CallAddGQL,
              private router: Router) {

    for (let i = 0; i < this.instancesNumber; i++) {
      this.callServices.push(new CallService(
        this.sessionRequestGQL,
        this.contactRequestGQL,
        this.callAddGQL,
        i,
        this,
        router
      ));
    }
  }

  start(isOneCall = false) {

    this.currentCallService = null;

    const currentHours = new Date().getHours();

    for (const callService of this.callServices) {
      callService.resume(isOneCall);

      if (isOneCall || currentHours < 12) {
        break;
      }
    }
  }

  pause() {

    this.currentCallService = null;
    for (const callService of this.callServices) {
      callService.pause();
    }
  }

  onError(error: string, callService: CallService) {

    if (callService !== this.currentCallService) {
      return;
    }

    this.callHandler.onError(error, callService);
  }

  onAnswered(answeredCallService: CallService) {

    for (const callService of this.callServices) {

      if (callService === answeredCallService) {
        this.currentCallService = answeredCallService;
      } else {
        callService.pause();
      }
    }

    this.callHandler.onAnswered(answeredCallService);
  }

  onDone(callService: CallService) {

    if (callService !== this.currentCallService) {
      return;
    }

    this.callHandler.onDone(callService);
  }
}
