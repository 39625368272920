import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CallHandlerInterface } from 'src/app/interfaces/call-handler.interface';
import { CallService } from 'src/app/services/call.service';
import { MultiCallService } from 'src/app/services/multi-call.service';

import {
  CallAddGQL, CallCommercials, CallCommercialsGQL, CallInput, CallMail, CallMailGQL, CallResultEnum, CallResultExtraEnum,
  CallSendMailGQL,
  CivilityEnum, ContactEditGQL, ContactInput, ContactRequest, SessionRequest, TaxEnum
} from '../../graphql/generated/graphql';

@Component({
  selector: 'app-call',
  templateUrl: './call.component.html',
  styleUrls: ['./call.component.scss']
})
export class CallComponent implements OnInit, CallHandlerInterface {

  error = null;
  isLoading = false;

  callInput: CallInput = { result: CallResultEnum.NoAnswer } as CallInput;

  /* Call Time */
  callTimeInterval: any;
  callSeconds = 0;

  /* After call */
  afterCallCountDown = 0;
  afterCalltimeInterval: any;

  /* Popup */
  currentPopUp: CallResultEnum;

  /* Simulation */
  isSimulationPopup = false;

  netMensuelRevenu = 0;
  mensuelRentalOrPrincipalResidenceCredit = 0;
  mensuelCreditOtherResidence = 0;
  netMensuelOtherRevenu = 0;
  mensuelConsommationCredit = 0;
  mensuelCarCredit = 0;
  mensuelPension = 0;

  creditCapacity = 0;
  isFinancable = null;

  isCallScript = false;

  /* Meeting commercials */
  commercials: CallCommercials.CallCommercials[] = [];
  selectedCommercial: string = null;
  currentCommercial = null;
  currentDate = null;

  /* Contact */
  contact: ContactRequest.ContactRequest;
  session: SessionRequest.SessionRequest;

  lastCallStatus: string;

  mail: CallMail.CallMail;
  isMailPop = false;

  isOneCall = false;

  constructor(public multiCallService: MultiCallService,
              private callCommercialsGQL: CallCommercialsGQL,
              private callMailGQL: CallMailGQL,
              private callSendMailGQL: CallSendMailGQL,
              private contactEditGQL: ContactEditGQL,
              private callAddGQL: CallAddGQL,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {

      this.isOneCall = params['is-one-call'] === 'true';

      this.multiCallService.callHandler = this;
      this.multiCallService.start(this.isOneCall);
    });

  }

  /* Call Logic */
  onError(error: string, callService: CallService) {
    this.displayError(error);
  }

  onAnswered(callService: CallService) {

    this.lastCallStatus = null;

    if (this.contact == null) {
      this.contact = callService.contact;
    }

    this.session = callService.session;

    this.callSeconds = 0;

    this.callTimeInterval = setInterval(() => {
      this.callSeconds++;
    }, 1000);
  }

  onDone(callService: CallService) {

    if (this.callTimeInterval != null) {
      clearInterval(this.callTimeInterval);
      this.callTimeInterval = null;
    }

    this.startAfterCallCountDown();
  }

  startAfterCallCountDown() {

    let timeToWait = 30;

    if (this.currentPopUp != null) {
      switch (this.currentPopUp) {

        case CallResultEnum.Wrong:
          timeToWait = 60;
          break;
        case CallResultEnum.NotInterested:
          timeToWait = 60;
          break;
        case CallResultEnum.OutOfTarget:
          timeToWait = 60;
          break;
        case CallResultEnum.CallBack:
          timeToWait = 120;
          break;
        case CallResultEnum.Meeting:
          timeToWait = 300;
          break;
      }
    }

    this.afterCallCountDown = timeToWait;
    this.afterCalltimeInterval = setInterval(() => {
      this.tikAfterCall();
    }, 1000);
  }

  tikAfterCall() {

    this.afterCallCountDown--;

    if (this.afterCallCountDown <= 0) {
      this.nextCall();
    }
  }

  nextCall() {

    if (this.afterCalltimeInterval != null) {

      clearInterval(this.afterCalltimeInterval);
    }

    this.contactEdit();
  }

  recall() {

    if (this.afterCalltimeInterval != null) {

      clearInterval(this.afterCalltimeInterval);
    }

    this.multiCallService.currentCallService.recall();
  }

  endCall() {

    this.multiCallService.currentCallService.disconnect();
  }

  stopCall() {

    this.multiCallService.pause();
    this.router.navigate(['/home']);
  }

  contactEdit() {

    // this.netMensuelRevenu = this.netMensuelRevenu == null ? 0 : this.netMensuelRevenu;
    // this.mensuelCredit = this.mensuelCredit == null ? 0 : this.mensuelCredit;
    // this.mensuelConsommationCredit = this.mensuelConsommationCredit == null ? 0 : this.mensuelConsommationCredit;
    // this.mensuelPension = this.mensuelPension == null ? 0 : this.mensuelPension;

    const contactInput: ContactInput = {

      civility: (this.contact.civility as string) === 'null' || this.contact.civility == null ? undefined : this.contact.civility,
      firstName: this.contact.firstName,
      lastName: this.contact.lastName,
      email: this.contact.email,
      phoneNumber: this.contact.phoneNumber,
      personalPhoneNumber: this.contact.personalPhoneNumber,
      professionalPhoneNumber: this.contact.professionalPhoneNumber,
      birthDate: this.contact.birthDate,
      familySituation: (this.contact.familySituation as string) === 'null' || this.contact.familySituation == null
        ? undefined : this.contact.familySituation,
      kids: (this.contact.kids as unknown) === 'null' || this.contact.kids == null ? undefined : parseInt(this.contact.kids + '', 0),
      revenu: (this.contact.revenu as string) === 'null' || this.contact.revenu == null ? undefined : this.contact.revenu,
      tax: (this.contact.tax as string) === 'null' || this.contact.tax == null ? undefined : this.contact.tax,
      residence: (this.contact.residence as string) === 'null' || this.contact.residence == null ? undefined : this.contact.residence,
      zip: this.contact.zip,
      city: this.contact.city,
      address: this.contact.address,
      // netMensuelRevenu: this.netMensuelRevenu,
      // mensuelCredit: this.mensuelCredit,
      // mensuelConsommationCredit: this.mensuelConsommationCredit,
      // mensuelPension: this.mensuelPension,
    };

    this.isLoading = true;
    this.contactEditGQL.mutate({
      contactId: this.contact.id,
      contactInput
    }).subscribe(result => {

      if (result.data != null) {

        this.isLoading = false;

        this.callAdd();
      } else {

        this.error = 'Erreur de qualification / Contact';

        this.isLoading = false;
      }

    }, _ => {
      this.error = 'Erreur de qualification / Contact / Internet';
    });
  }

  callAdd() {

    switch (this.callInput.result) {

      case CallResultEnum.Wrong:
        this.applyWrong();
        break;

      case CallResultEnum.NotInterested:
        this.applyNotInterested();
        break;

      case CallResultEnum.OutOfTarget:
        this.applyOutOfTarget();
        break;

      case CallResultEnum.CallBack:
        this.applyCallBack();
        break;

      case CallResultEnum.NoAnswer:
        this.applyNoAnswer();
        break;

      case CallResultEnum.Meeting:
        this.applyMeeting();
        break;
    }

    if (this.callInput.result === CallResultEnum.Meeting && this.currentCommercial) {
      this.callInput.commercial = this.currentCommercial.identifier;
    }

    this.callInput.resultExtra = (this.callInput.resultExtra as string) === 'null' || this.callInput.resultExtra == null
      ? undefined : this.callInput.resultExtra;

    this.isLoading = true;
    this.callAddGQL.mutate({
      contactId: this.contact.id,
      sessionId: this.session.id,
      callInput: this.callInput
    }).subscribe(result => {

      if (result.data != null) {

        this.isLoading = false;

        switch (this.callInput.result) {

          case CallResultEnum.CallBack:
            this.lastCallStatus = 'Relance tel enregistré.';
            break;

          case CallResultEnum.Meeting:
            this.lastCallStatus = 'Rendez-vous enregistré.';
            break;

          case CallResultEnum.NoAnswer:
            this.lastCallStatus = 'NRP enregistré.';
            break;

          case CallResultEnum.NotInterested:
            this.lastCallStatus = 'Non intéressé enregistré.';
            break;

          case CallResultEnum.OutOfTarget:
            this.lastCallStatus = 'Hors cible enregistré.';
            break;

          case CallResultEnum.Wrong:
            this.lastCallStatus = 'Faux enregistré.';
            break;
        }

        if (this.callInput.result === CallResultEnum.Meeting) {
          this.loadMail();
        } else {
          this.reset();

          if (this.isOneCall) {
            this.router.navigate(['agenda']);
          } else {
            this.multiCallService.start();
          }
        }
      } else {
        this.error = 'Erreur de qualification / Appel';
        this.isLoading = false;
      }

    }, _ => {
      this.error = 'Erreur de qualification / Appel / Internet';
    });
  }

  reset() {

    this.isLoading = false;

    this.callInput = { result: CallResultEnum.NoAnswer } as CallInput;

    this.mail = null;

    /* Call Time */
    this.callTimeInterval = null;
    this.callSeconds = 0;

    /* After call */
    this.afterCallCountDown = 0;
    this.afterCalltimeInterval = null;

    /* Popup */
    this.currentPopUp = null;

    /* Simulation */
    this.isSimulationPopup = false;

    this.netMensuelRevenu = 0;
    this.mensuelRentalOrPrincipalResidenceCredit = 0;
    this.mensuelCreditOtherResidence = 0;
    this.netMensuelOtherRevenu = 0;
    this.mensuelConsommationCredit = 0;
    this.mensuelCarCredit = 0;
    this.mensuelPension = 0;
    this.creditCapacity = 0;
    this.isFinancable = null;

    /* Meeting commercials */
    this.commercials = [];
    this.selectedCommercial = null;
    this.currentCommercial = null;
    this.currentDate = null;

    /* Contact */
    this.contact = null;
    this.session = null;

    this.isCallScript = false;

    this.isMailPop = false;
  }

  /* Start Popup */
  startPopup(callResult: CallResultEnum) {

    if (callResult === CallResultEnum.Meeting) {

      if (this.callInput.meetingZip == null || this.callInput.meetingZip === '') {
        this.callInput.meetingZip = this.contact.zip;
        this.callInput.meetingCity = this.contact.city;
        this.updateMeetings();
      }

      if (this.callInput.meetingAddress == null || this.callInput.meetingAddress === '') {
        this.callInput.meetingAddress = this.contact.address;
      }
    }

    this.currentPopUp = callResult;
  }

  cancelPopup() {

    this.currentPopUp = null;
  }

  startSimulationPopup() {

    this.isSimulationPopup = true;
  }

  closeSimulationPopup() {

    this.isSimulationPopup = false;
  }

  startCallScriptPopup() {

    this.isCallScript = true;
  }

  closeCallScriptPopup() {

    this.isCallScript = false;
  }

  applyNoAnswer() {

    this.callInput.meetingDate = null;
    this.callInput.meetingAddress = null;
    this.callInput.meetingZip = null;
    this.callInput.meetingCity = null;
    this.callInput.callBackDate = null;
    this.callInput.commercial = null;

    this.callInput.resultExtra = null;
    this.callInput.comment = null;

    this.callInput.result = CallResultEnum.NoAnswer;
  }

  applyWrong() {

    this.callInput.meetingDate = null;
    this.callInput.meetingAddress = null;
    this.callInput.meetingZip = null;
    this.callInput.meetingCity = null;
    this.callInput.callBackDate = null;
    this.callInput.commercial = null;

    if (this.callInput.resultExtra !== CallResultExtraEnum.WrongNumber &&
      this.callInput.resultExtra !== CallResultExtraEnum.WrongDetails) {

      this.callInput.resultExtra = null;
    }

    this.callInput.result = CallResultEnum.Wrong;

    this.currentPopUp = null;
  }

  applyOutOfTarget() {

    this.callInput.meetingDate = null;
    this.callInput.meetingAddress = null;
    this.callInput.meetingZip = null;
    this.callInput.meetingCity = null;
    this.callInput.callBackDate = null;
    this.callInput.commercial = null;

    if (this.callInput.resultExtra !== CallResultExtraEnum.NotFinancable &&
      this.callInput.resultExtra !== CallResultExtraEnum.OutTax &&
      this.callInput.resultExtra !== CallResultExtraEnum.MoreThan_65) {

      this.callInput.resultExtra = null;
    }

    this.callInput.result = CallResultEnum.OutOfTarget;

    this.currentPopUp = null;
  }

  applyNotInterested() {

    this.callInput.meetingDate = null;
    this.callInput.meetingAddress = null;
    this.callInput.meetingZip = null;
    this.callInput.meetingCity = null;
    this.callInput.callBackDate = null;
    this.callInput.commercial = null;

    if (this.callInput.resultExtra !== CallResultExtraEnum.AcquirePrincipalResidence &&
      this.callInput.resultExtra !== CallResultExtraEnum.Concurrent &&
      this.callInput.resultExtra !== CallResultExtraEnum.NotConveniant) {

      this.callInput.resultExtra = null;
    }

    this.callInput.result = CallResultEnum.NotInterested;

    this.currentPopUp = null;
  }

  applyCallBack() {

    if (this.callInput.callBackDate == null) {
      this.displayError('La date est obligatoire');
      return;
    }

    this.callInput.meetingDate = null;
    this.callInput.meetingAddress = null;
    this.callInput.meetingZip = null;
    this.callInput.meetingCity = null;
    this.callInput.resultExtra = null;
    this.callInput.commercial = null;

    this.callInput.result = CallResultEnum.CallBack;

    this.currentPopUp = null;
  }


  updateMeetings() {

    this.commercials = [];
    this.currentCommercial = null;
    this.currentDate = null;
    this.callInput.meetingDate = null;
    this.selectedCommercial = null;

    this.isLoading = true;
    this.callCommercialsGQL.fetch({ zip: this.callInput.meetingZip }).subscribe(result => {

      if (result.data != null) {

        this.commercials = result.data.callCommercials;

        this.isLoading = false;
      } else {

        this.displayError(result.errors[0].message);
        this.isLoading = false;
      }

    }, _ => { });
  }

  selectMeetingDate(date) {

    if (this.callInput.meetingDate === date) {
      this.callInput.meetingDate = null;
      return;
    }

    this.callInput.meetingDate = date;
  }

  applyMeeting() {

    if (this.callInput.meetingZip == null || this.callInput.meetingAddress == null || this.callInput.meetingCity == null) {
      this.displayError('Veuillez remplir l\'adresse et le code postal.');
      return;
    }

    if (this.callInput.meetingDate == null) {
      this.displayError('Veuillez selectionner la date et l\'heure de rendez-vous.');
      return;
    }

    this.callInput.resultExtra = null;

    this.callInput.result = CallResultEnum.Meeting;

    this.currentPopUp = null;
  }

  translateCivility(civility: CivilityEnum) {

    switch (civility) {

      case CivilityEnum.Mr:
        return 'Mr';
      case CivilityEnum.Mrs:
        return 'Madame';
      case CivilityEnum.Mr:
        return 'Mademoiselle';
    }
  }

  translateTax(tax: TaxEnum) {

    switch (tax) {

      case TaxEnum.TaxLess_1500:
        return 'Moins de 1 500€';
      case TaxEnum.Tax_1500_2500:
        return '1 500€ - 2 500€';
      case TaxEnum.Tax_2500_5000:
        return '2 500€ - 5 000€';
      case TaxEnum.Tax_5000_10000:
        return '5 000€ - 10 000€';
      case TaxEnum.TaxMore_10000:
        return 'Plus de 10 000€';
    }
  }

  translateDate(date): string {

    return date.substring(11, 16);
  }

  simulationCalculate() {

    this.netMensuelRevenu = this.netMensuelRevenu == null ? 0 : this.netMensuelRevenu;
    this.mensuelRentalOrPrincipalResidenceCredit = this.mensuelRentalOrPrincipalResidenceCredit == null ?
    0 : this.mensuelRentalOrPrincipalResidenceCredit;
    this.mensuelCreditOtherResidence = this.mensuelCreditOtherResidence == null ? 0 : this.mensuelCreditOtherResidence;
    this.netMensuelOtherRevenu = this.netMensuelOtherRevenu == null ? 0 : this.netMensuelOtherRevenu;
    this.mensuelConsommationCredit = this.mensuelConsommationCredit == null ? 0 : this.mensuelConsommationCredit;
    this.mensuelCarCredit = this.mensuelCarCredit == null ? 0 : this.mensuelCarCredit;
    this.mensuelPension = this.mensuelPension == null ? 0 : this.mensuelPension;

    const charges = this.mensuelRentalOrPrincipalResidenceCredit + this.mensuelCreditOtherResidence +
    this.mensuelConsommationCredit + this.mensuelCarCredit;

    this.creditCapacity = (this.netMensuelRevenu + this.netMensuelOtherRevenu) * 0.35 - charges;

    this.isFinancable = this.creditCapacity >= 350;
  }

  simulationReset() {

    this.netMensuelRevenu = 0;
    this.mensuelRentalOrPrincipalResidenceCredit = 0;
    this.mensuelCreditOtherResidence = 0;
    this.netMensuelOtherRevenu = 0;
    this.mensuelConsommationCredit = 0;
    this.mensuelCarCredit = 0;
    this.mensuelPension = 0;
    this.creditCapacity = 0;
    this.isFinancable = null;
  }

  extractZip(address: string): string {

    if (address == null) {
      return '';
    }

    const parts = address.match(/[0-9]+/g);

    if (parts == null) {
      return '';
    }

    for (const part of parts) {
      if (part.length === 5) {
        return part;
      }
    }

    return '';
  }

  formatCallSeconds(callSeconds: number) {

    const minutes = Math.trunc(callSeconds / 60);
    const seconds = callSeconds % 60;

    let result = '';

    if (minutes < 10) {
      result += '0' + minutes;
    } else {
      result += minutes;
    }

    result += ':';

    if (seconds < 10) {
      result += '0' + seconds;
    } else {
      result += seconds;
    }

    return result;
  }

  displayError(error: string) {
    this.error = error;
  }

  closeError() {
    this.error = null;
  }

  translateResult(result: CallResultEnum): string {

    switch (this.callInput.result) {

      case CallResultEnum.CallBack:
        return 'Relance tel';

      case CallResultEnum.Meeting:
        return 'Rendez-vous';

      case CallResultEnum.NoAnswer:
        return 'NRP';

      case CallResultEnum.NotInterested:
        return 'Non intéressé';

      case CallResultEnum.OutOfTarget:
        return 'Hors cible';

      case CallResultEnum.Wrong:
        return 'Faux';
    }
  }



  /* Mail */

  loadMail() {

    this.isMailPop = true;
    this.isLoading = true;
    this.callMailGQL.fetch({ contactId: this.contact.id }).subscribe(result => {

      if (result.data != null) {

        this.mail = result.data.callMail;

        this.isLoading = false;
      } else {

        this.displayError(result.errors[0].message);
        this.isLoading = false;
      }

    }, _ => { });
  }

  sendMail() {

    const mailInput = {
      subject: this.mail.subject,
      body: this.mail.body
    };

    this.isLoading = true;
    this.callSendMailGQL.mutate({
      contactId: this.contact.id,
      mailInput
    }).subscribe(result => {

      if (result.data != null) {

        this.isLoading = false;

        this.reset();

        if (this.isOneCall) {
          this.router.navigate(['agenda']);
        } else {
          this.multiCallService.start();
        }
      } else {

        this.error = 'Erreur envoi de mail';

        this.isLoading = false;
      }

    }, _ => {
      this.error = 'Erreur envoi de mail / Internet';
    });
  }
}
